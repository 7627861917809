
import { Component, Vue, Prop, } from 'vue-property-decorator'
import { mapGetters, } from 'vuex'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import { ControListInterface, } from '@/store/types/Analysis/ControlList'
import NavigationGapComponent from '@/components/NavigationGap/Index.vue'

@Component({
  components: {
    NavigationGapComponent,OverlayBackground
  },
  ...mapGetters('GapModule', ['getCategorieSelected',]),
})
export default class GapAnalisisComponent extends Vue {
  @Prop({ required: false, default: true, }) readonly implementer!: boolean
  private loading = false;
  private gapControlList: ControListInterface[] = []
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
  private versionApp = this.$store.state.GapModule.GapState.version_categories
  private localCategoriaId: number | undefined
  private templateIndex = 0
  private newindex = 0
  private finalList = false
  private categories = this.$store.state.GapModule.GapState.categories
  private role = this.$store.state.AuthModule.AuthState.role
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];

  mounted () {
    this.localCategoriaId = parseInt(this.$route.params.id_category)
    this.getCategories()
  }

  private changeView (element: number) {
    this.$emit('changeView', element)
  }

  private goDetailControl (element: ControListInterface) {
    this.$store.commit('GapModule/SET_DETAIL_SELECTED', element)
    this.$mixpanel.track('Control', {'Titulo': element.name,})
    this.$emit('goDetailControl', element)

    const id_control = element.id

    // If para saber si entra por compañia o por Implementador

    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      // If para saber si entra en modo History o modo estado Actual
      this.versionApp === 0
        ? this.$router.push({
          name: 'startupDetailControl',
          params: { id_control: id_control ? id_control.toString() : 'null', },
        })
        : this.$router.push({
          name: 'historyStartupDetailControl',
          params: { id_control: id_control ? id_control.toString() : 'null', },
        })
    } else {
      // Entra por modo implementador

      // If para saber si entra por modo History o estatus actual (( === 0 Estado actual : Estado History))

      if (this.versionApp === 0) {
        if (this.role.includes ('Customer Success')) {
          this.$router.push({
            name: 'detailControl',
            params: { id_control: id_control ? id_control.toString() : 'null', },
          })
        } else {
          this.$router.push({
            name: 'editControl',
            params: { id_control: id_control ? id_control.toString() : 'null', },
          })
        }
      } else {
        this.$router.push({
          name: 'historyDetailControl',
          params: { id_control: id_control ? id_control.toString() : 'null', },
        })
      }
    }
  }

  private nextControlBack (template: ControListInterface) {
    this.templateIndex = this.categories.findIndex((p: ControListInterface) => p.id === template.id)
    this.newindex = this.templateIndex - 1
    this.localCategoriaId = this.categories[this.templateIndex - 1].id
    this.newindex === this.categories.length - 1
      ? (this.finalList = true)
      : (this.finalList = false)
    this.$store.commit('GapModule/SET_CONTROL_SELECTED', this.categories[this.templateIndex - 1])
    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      this.$router.push({
        name: 'startupControlsList',
        params: {
          id_category: this.localCategoriaId ? this.localCategoriaId.toString() : 'null',
        },
      })
    } else {
      this.$router.push({
        name: 'controlsList',
        params: {
          id_category: this.localCategoriaId ? this.localCategoriaId.toString() : 'null',
        },
      })
    }

    this.getCategories()
  }

  private nextControlFront (template: ControListInterface) {
    this.templateIndex = this.categories.findIndex((p: ControListInterface) => p.id === template.id)
    this.localCategoriaId = this.categories[this.templateIndex + 1].id
    this.newindex = this.templateIndex + 1
    this.newindex === this.categories.length - 1
      ? (this.finalList = true)
      : (this.finalList = false)
    this.$store.commit('GapModule/SET_CONTROL_SELECTED', this.categories[this.templateIndex + 1])
    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      this.$router.push({
        name: 'startupControlsList',
        params: {
          id_category: this.localCategoriaId ? this.localCategoriaId.toString() : 'null',
        },
      })
    } else {
      this.$router.push({
        name: 'controlsList',
        params: {
          id_category: this.localCategoriaId ? this.localCategoriaId.toString() : 'null',
        },
      })
    }
    this.getCategories()
  }

  private controlChange (element: ControListInterface) {
    this.$store.commit('GapModule/SET_CONTROL_SELECTED', element)
    this.newindex = this.categories.findIndex((p: ControListInterface) => p.id === element.id)
    this.newindex === this.categories.length - 1
      ? (this.finalList = true)
      : (this.finalList = false)
    this.localCategoriaId = element.id
    if (!this.ADMIN_ROLES.some((adminRole: string) => this.role.includes(adminRole))){
      this.$router.push({
        name: 'startupControlsList',
        params: {
          id_category: this.localCategoriaId ? this.localCategoriaId.toString() : 'null',
        },
      })
    } else {
      this.$router.push({
        name: 'controlsList',
        params: {
          id_category: this.localCategoriaId ? this.localCategoriaId.toString() : 'null',
        },
      })
    }
    this.getCategories()
  }

  private getCategories () {
    this.loading = true    
    this.$store
      .dispatch('GapModule/getGapControlList', {categoryId:this.localCategoriaId,frameworkId:this.$route.params.id})
      .then((data) => {
        this.gapControlList = data.data.gap_analysis_controls
        this.newindex = this.categories.findIndex(
          (p: ControListInterface) => p.id === this.localCategoriaId
        )
        this.newindex === this.categories.length - 1
          ? (this.finalList = true)
          : (this.finalList = false)
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }
}
